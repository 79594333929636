import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Divider, Modal, Row, Table} from "antd";
import {getLocalDate} from "../../utils/utils";
import {CURRENCY_SYMBOLS, SELLER_CENTRAL_URL} from "../../utils/constant";
import Spinner from "react-bootstrap/Spinner";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";

function Invoices() {
    const [searchParams] = useSearchParams();
    const [invoices, setInvoices] = useState([])
    const [currentDisplayingInvoice, setCurrentDisplayingInvoice] = useState()
    const [qontoInvoices, setQontoInvoice] = useState([])
    const [textModal, setTextModal] = useState(false);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [modalCallback, setModalCallback] = useState(null);
    const [modalCheckValue, setModalCheckValue] = useState(false);
    const [modalEmail, setModalEmail] = useState("");
    const {user} = useSelector((state) => state.auth);

    const fetchInvoices = () => {
        if (user.role === 'Admin')
            axios.get(`/invoices/${searchParams.get('user') ? `?user=` + searchParams.get('user') : ''}`)
                .then(response => {
                    const inv = response.data.sort((a, b) => b.createdAt - a.createdAt)
                    setInvoices(inv.map(x => ({
                        ...x,
                        check: parseInt(x.commissionDiscount) !== Math.round((((x.total_excluding_tax ?? 0)) / x.amount * 100)),
                        invoice_present: x.qonto?.invoice?.client_invoice?.status === 'unpaid'
                    })));
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error fetching reimbursements:', error);
                });
        else
            axios.get(`/invoices/`)
                .then(response => {
                    const inv = response.data.sort((a, b) => b.createdAt - a.createdAt)
                    setInvoices(inv.map(x => ({
                        ...x,
                        check: parseInt(x.commissionDiscount) !== Math.round((((x.total_excluding_tax ?? 0)) / x.amount * 100)),
                        invoice_present: x.qonto?.invoice?.client_invoice?.status === 'unpaid'
                    })));
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error fetching reimbursements:', error);
                });
    };

    useEffect(() => {
        fetchInvoices()
        fetchQontoInvoices()
    }, []);

    const fetchQontoInvoices = () => {
        axios.get(`/qonto/invoices`)
            .then(response => {
                setQontoInvoice(response.data);
            })
            .catch(error => {
                // Handle errors here
                console.error('Error fetching reimbursements:', error);
            });
    };

    const invoice_columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            key: '_id',
            hasFilter: true
        },
        {
            title: 'Company Name',
            dataIndex: 'invoice_client',
            key: 'invoice_client',
            hasFilter: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            hasFilter: true
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            hasFilter: true
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Invoicing period',
            dataIndex: 'invoicing_period',
            key: 'invoicing_period',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'net',
        },
        {
            title: 'IVA',
            dataIndex: 'iva',
            key: 'iva',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Reimbursements total',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Calculated %',
            dataIndex: 'percentage',
            key: 'percentage',
        },
        {
            title: 'Import check',
            dataIndex: 'check',
            key: 'check',
            hasFilter: true,
            hasCustomFilter: true
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            hasFilter: true,
            hasCustomFilter: true
        },
        {
            title: 'Qonto Invoice',
            dataIndex: 'invoice_present',
            key: 'invoice_present',
            hasFilter: true,
            hasCustomFilter: true
        },
        {
            title: 'Qonto Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            hasFilter: true,
        },
        {
            title: 'Payment errors',
            dataIndex: 'payment_errors',
            key: 'payment_errors',
            hasFilter: true,
            hasCustomFilter: true
        },
        {
            title: 'Paid at',
            dataIndex: 'paid_at',
            key: 'paid_at',
        },
        {
            title: 'Pay',
            dataIndex: 'pay',
            key: 'pay',
        },
        {
            title: 'Send Draft',
            dataIndex: 'sendDraft',
            key: 'sendDraft',
        },
        {
            title: 'Send Invoice',
            dataIndex: 'sendInvoice',
            key: 'sendInvoice',
        },
        {
            title: 'Mark paid',
            dataIndex: 'markPaid',
            key: 'markPaid',
        },
        {
            title: 'Download',
            dataIndex: 'invoice_pdf',
            key: 'invoice_pdf',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
        {
            title: 'Invoice status',
            dataIndex: 'invoice_status',
            key: 'invoice_status',
        },
    ];
    const invoice_columns_user = [
        {
            title: 'Id',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Invoicing period',
            dataIndex: 'invoicing_period',
            key: 'invoicing_period',
        },
        {
            title: 'Net',
            dataIndex: 'net',
            key: 'net',
        },
        {
            title: 'IVA',
            dataIndex: 'iva',
            key: 'iva',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Amazon Reimbursements total',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
        },
        // {
        //     title: 'Download',
        //     dataIndex: 'invoice_pdf',
        //     key: 'invoice_pdf',
        // },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
    ];
    const cases_columns = [
        {
            title: 'ReimbursementId',
            dataIndex: 'reimbursementId',
            key: 'reimbursementId',
        },
        {
            title: 'FNSKU',
            dataIndex: 'fnsku',
            key: 'fnsku',
        },
        {
            title: 'Amount',
            dataIndex: 'amountReimbursed',
            key: 'amountReimbursed',
        },
        {
            title: 'Amount Billed',
            dataIndex: 'amountBilled',
            key: 'amountBilled',
        },
    ];

    const onlyUnique = (value, index, array) => {
        return value !== '' && array.indexOf(value) === index;
    }

    const mergedColumns = invoice_columns.map((col) => {
        if (col?.hasFilter) {
            if (col?.hasCustomFilter) {
                return {
                    ...col,
                    filters: [{text: 'True', value: true}, {text: 'False', value: false}],
                    filterMode: 'menu',
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record[col?.key]?.props?.check === value
                    },
                };
            } else
                return {
                    ...col,
                    filters: invoices.map(x => ({text: x[col?.key], value: x[col?.key]}))?.filter(onlyUnique),
                    filterMode: 'menu',
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record[col?.key]?.includes(value)
                    },
                };
        } else return col
    });

    let locale = {
        emptyText: (
            <div className={'box content'}><Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner></div>
        )
    };

    const markPaid = (invoiceId, email) => {
        showConfirmationModal(() => {
            axios.post(`/invoices/mark`, {invoiceId})
                .then(response => {
                    toast.success('Success')
                })
                .catch(error => {
                    console.error('Error fetching reimbursements:', error);
                    toast.error('Generic error')
                });
        }, email, "", <>Vuoi davvero segnare la fattura {invoiceId} dell'utente {email} come pagata?</>)
    }
    const sendInvoice = (invoiceId, email) => {
        showConfirmationModal(() => {
            axios.post(`/invoices/invoice`, {invoiceId})
                .then(response => {
                    toast.success('Success')
                })
                .catch(error => {
                    console.error('Error fetching reimbursements:', error);
                    toast.error('Generic error')
                });
        }, email, "", <>Vuoi davvero inviare la fattura {invoiceId} dell'utente {email} allo SDI?</>)
    }
    const sendDraft = (invoiceId, email) => {
        showConfirmationModal(() => {
            axios.post(`/invoices/draft`, {invoiceId})
                .then(response => {
                    toast.success(<>Success - Invoice url <a href={response.data.client_invoice.invoice_url}
                                                             target="_blank"
                                                             rel="noreferrer">{response.data.client_invoice?.invoice_url}</a></>)
                })
                .catch(error => {
                    console.error('Error fetching reimbursements:', error);
                    toast.error('Generic error')
                });
        }, email, "", <>Vuoi davvero inviare la fattura {invoiceId} dell'utente {email} via mail come draft?</>)
    }

    const sendDraftClient = (invoiceId, email) => {
        axios.post(`/invoices/draft`, {invoiceId})
            .then(response => {
                toast.success(<>Success - Invoice url <a href={response.data.client_invoice.invoice_url}
                                                         target="_blank"
                                                         rel="noreferrer">{response.data.client_invoice?.invoice_url}</a></>)
                window.open(response.data.client_invoice?.invoice_url)
            })
            .catch(error => {
                console.error('Error fetching reimbursements:', error);
                toast.error('Error downloading invoice')
            });
    }

    const pay = (invoiceId, email) => {
        showConfirmationModal(() => {
            axios.post(`/invoices/pay`, {invoiceId})
                .then(response => {
                    toast.success('Success')
                })
                .catch(error => {
                    console.error('Error fetching reimbursements:', error);
                    toast.error('Generic error')
                });
        }, email, "", <>Vuoi davvero pagare la fattura {invoiceId} dell'utente {email}?</>)
    }
    const showConfirmationModal = (callback, email, fieldValue, text) => {
        setTextModal(text)
        setIsConfirmationModalVisible(true);
        setModalCallback(() => callback);
        setModalEmail(email);
        setModalCheckValue(fieldValue);
    }

    return (
        <Row>
            <Modal key={"confirmationModal"}
                   title="Conferma"
                   destroyOnHide={true}
                   open={isConfirmationModalVisible}
                   onOk={() => {
                       if (modalCallback) modalCallback();
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
                   onCancel={() => {
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
            >
                {textModal}
            </Modal>
            <Col>
                <h3><strong>Invoices</strong></h3>
                {/*{user.role === 'Admin' && <>*/}
                {/*    <p>TOTAL: {invoices.reduce((acc, item) => acc + (item?.total ?? 0) / 100, 0)}</p></>}*/}
                <Table locale={locale} pagination={false}
                       dataSource={invoices.sort((a, b) => b.invoicing_period.localeCompare(a.invoicing_period)).map(x => ({
                           paid: x?.paid ? <CheckCircleOutlined style={{color: 'green'}} check={true}/> :
                               <CheckCircleOutlined style={{color: 'red'}} check={false}/>,
                           _id: x._id,
                           email: x.email,
                           role: x.role,
                           commission: x.commissionDiscount,
                           payment_errors: x.stripe?.payment && x.stripe?.payment?.last_payment_error?.decline_code ? <>{x.stripe?.payment ? new Date(x.stripe?.payment?.created * 1000).toISOString() : ''} {x.stripe?.payment?.last_payment_error?.decline_code}</> : "",
                           invoice_pdf: <Button
                               type={"primary"}
                               onClick={() => {
                                   x.qonto?.invoice?.client_invoice?.status === 'unpaid' ?
                                       window.open(x.qonto?.invoice?.client_invoice?.invoice_url) :
                                       sendDraftClient(x._id, x.email)
                               }
                               }>Download
                           </Button>,
                           net: (x.total_excluding_tax ?? 0) / 100 + '€',
                           total: (x.total ?? 0) / 100 + '€',
                           date: getLocalDate(new Date(x.createdAt)),
                           details: <Button
                               type={currentDisplayingInvoice === x ? "primary" : "dashed"}
                               onClick={() => {
                                   setCurrentDisplayingInvoice(x)
                               }}>Details
                           </Button>,
                           sendInvoice: <Button
                               type={"primary"}
                               disabled={x.qonto?.invoice?.client_invoice?.status === 'unpaid' || !x.needs_invoice}
                               onClick={() => {
                                   sendInvoice(x._id, x.email)
                               }}>Send Invoice
                           </Button>,
                           pay: <Button
                               type={"primary"}
                               disabled={x.paid || x.role === 'Iban' || x.role === 'Cash'}
                               onClick={() => {
                                   pay(x._id, x.email)
                               }}>Pay
                           </Button>,
                           markPaid: <Button
                               type={"primary"}
                               disabled={x.paid}
                               onClick={() => {
                                   markPaid(x._id, x.email)
                               }}>Mark Paid
                           </Button>,
                           sendDraft: <Button
                               type={"primary"}
                               disabled={x.qonto?.invoice?.client_invoice?.status === 'unpaid' || !x.needs_invoice}
                               onClick={() => {
                                   sendDraft(x._id, x.email)
                               }}>Send Draft
                           </Button>,
                           amount: (x.amount / 100) + '€',
                           invoice_present: x.invoice_present ?
                               <CheckCircleOutlined style={{color: 'green'}} check={true}/> :
                               <CloseCircleOutlined style={{color: 'red'}} check={false}/>,
                           iva: (x.tax / 100) + '€',
                           paid_at: x.stripe?.payment?.created ? new Date(x.stripe?.payment?.created * 1000).toISOString() : '',
                           invoicing_period: x.invoicing_period,
                           invoice_number: x.qonto?.invoice?.client_invoice?.number ?? '-',
                           invoice_client: x.qonto?.invoice?.client_invoice?.client?.name,
                           percentage: Math.round((((x.total_excluding_tax ?? 0)) / x.amount * 100)) + '%',
                           check: x.check ? <CloseCircleOutlined style={{color: 'red'}} check={false}/> :
                               <CheckCircleOutlined style={{color: 'green'}} check={true}/>,
                           invoice_status: <InvoiceStatus id={x.qonto?.invoice?.client_invoice?.id}
                                                          qontoInvoices={qontoInvoices}/>
                       }))} columns={user.role === 'Admin' ? mergedColumns : invoice_columns_user}/>
            </Col>
            <Divider type="vertical"/>
            <Col>
                <h3><strong>Cases</strong></h3>
                <Table locale={locale} pagination={false}
                       scroll={{x: '500px'}}
                       dataSource={currentDisplayingInvoice ? currentDisplayingInvoice?.items.map(x => {
                           const description_units = x.description?.split(' - ')
                           const reimbursementId = description_units && description_units[2] ? description_units[2] : ""
                           return {
                               reimbursementId: <a
                                   target="_blank"
                                   href={`${SELLER_CENTRAL_URL[x?.reimbursementId] ||
                                   "https://sellercentral.amazon.it"
                                   }/reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${x?.reimbursementId}",""],"pageOffset":1,"searchDays":730%7D`}
                                   className="queryLink" rel="noreferrer">
                                   {x.reimbursementId}
                               </a>,
                               fnsku: reimbursementId,
                               amountReimbursed: Math.round(x.amountReimbursement) / 100 + '€',
                               amountBilled: Math.round(x.amount) / 100 + '€'
                           }
                       }) : []} columns={cases_columns}/>
            </Col>
        </Row>
    );
}

const InvoiceStatus = ({id, qontoInvoices}) => {
    return <>
        <p>{qontoInvoices?.filter(x => x.id === id)[0]?.einvoicing_status}</p>
    </>
}

export default Invoices;